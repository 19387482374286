import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { RouterService } from 'src/app/services/router/router.service';

@Component({
  selector: 'app-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss'],
})
export class CartProductComponent implements OnInit {

  @Input() product; 

  constructor(private languageService: LanguagesService,
    private routerService: RouterService,
    private modalController: ModalController) { }

  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }

  ngOnInit() {}

  openCatalogue(){
    this.modalController.dismiss(null, null, "shopping-cart-modal");
    this.modalController.dismiss(null, null, "catalogue-detail-modal");
    this.routerService.redirect(`/app/catalogue`);
  }

  openCart(){
    this.modalController.dismiss(null, null, "shopping-cart-modal");
    this.modalController.dismiss(null, null, "catalogue-detail-modal");
    this.routerService.redirectRoot(`/app/shopping-cart`);
  }

  dismiss(){
    this.modalController.dismiss(null, null, "shopping-cart-modal");
    this.modalController.dismiss(null, null, "catalogue-detail-modal");
  }
}
