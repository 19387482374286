import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../storage/storage.service";
import { AuthService } from "../auth/auth.service";
import { AlertService } from "../alert/alert.service";

@Injectable({
  providedIn: "root",
})
export class FirebaseGuard implements CanActivate {
  constructor(
    private router: Router,
    private alertService: AlertService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean {
    const params = next.queryParams;
    if(params.mode){
        if(params.mode == 'verifyEmail'){
            this.alertService.presentAlert('Cuenta verificada correctamente');
            this.router.navigateByUrl('login');
        } else if (params.mode == 'resetPassword') {
            const url = `verify-code?oobCode=${params.oobCode}`
            this.router.navigateByUrl(url);
        }
    } else {
        return true;
    }
    return true;
  }
}
