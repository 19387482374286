import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-recognitions',
  templateUrl: './icon-recognitions.svg',
  styleUrls: ['./icon-recognitions.component.scss'],
})
export class IconRecognitionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
