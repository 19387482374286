import { DesignSystemModule } from 'src/theme/design-system/design-system.module';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { ComponentsModule } from '../components.module';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { BrMaskerModule } from 'br-mask';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@NgModule({
  declarations: [InvoiceFormComponent, InvoiceDetailComponent, InvoiceListComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    RouterModule,
    PipesModule,
    ComponentsModule,
    DesignSystemModule,
    BrMaskerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    InvoiceFormComponent,
    InvoiceDetailComponent,
    InvoiceListComponent
  ],
  providers: [
    AndroidPermissions
  ]
})
export class InvoiceModule { }
