import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { docs } from './pathsExhibition';

export type Filters = 'accepted' | 'in-progress' | 'rejected';
export type ChallengesTypes = 'challenge' | 'geochallenge';
export type ExhibitsTypes = 'invoice' | 'exhibition';
export type Status = 'idle' | 'pending' | 'resolved' | 'rejected';
export type ItemData = { [field: string]: any } & { status: Filters; uid: firebase.User['uid'] };
export type ListData = { id: string, data: ItemData }[];
@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  type: ExhibitsTypes = 'invoice'

  constructor(
    private firestore: AngularFirestore,
    private storageServices: StorageService
  ) { }

  newId() {
    return firebase.firestore().collection('temp').doc().id;
  }

  saveInvoice(data, uid) {
    var timestamp = new Date().getTime();
    const ID = timestamp.toString()
    const docRef = this.firestore.collection(`/ChallengeUsers/${uid}/Invoices/${uid}/Answers/`).doc(ID);
    docRef.set(data);
    return ID
  }

   async GetData() {
     ;
    const claims =  await this.storageServices.get("gnx-claims")
     const data =  this.firestore
      .collection(`/ChallengeUsers/${claims.uid}/Invoices/${claims.uid}/Answers/`, ref => ref.where('uid', '==', claims.uid))
      .snapshotChanges()
      .pipe(map(
        acitons => acitons.map(a => {
          const data : any  = a.payload.doc.data();
          const idRef = a.payload.doc.id;
          
          return { idRef, ...data };
        })
      ))
     
      return data;
      
  };

  async getDetail(id) {
    const claims = await this.storageServices.get("gnx-claims");
    return await this.firestore
      .collection(`/ChallengeUsers/${claims.uid}/Invoices/${claims.uid}/Answers/`)
      .ref
      .doc(id)
      .get()
      .then(data => {
        if (data.exists) {
          return data.data();
        }
      })
  }
}