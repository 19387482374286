import { Component, Input, OnInit } from '@angular/core';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

const InfoCardHistoryByState = {
  credit: {
    title: "@PoinstName obtenidos",
    Subtitle: "Ganados",
    type: "credit",
    PrefixDetail: "",
  },
  exchange: {
    title: " Redención de @PoinstName ",
    Subtitle: "Usados",
    type: "exchange",
    PrefixDetail: "Canje en ",
    PrefixDetail2: ""
  },
  expiry: {
    title: " Vencimiento de @PoinstName ",
    Subtitle: "Vencidos",
    type: "expiry"
  },
  adjustment: {
    title: " Ajuste de @PoinstName ",
    Subtitle: "Ajustados",
    type: "adjustment",
    PrefixDetail: "",
  },
  refund: {
    title: "Reembolso",
    Subtitle: "Reembolsados",
    type: "refund",
    PrefixDetail: "Reembolso en ",
    PrefixDetail2: ""
  },
}

@Component({
  selector: 'app-table-history',
  templateUrl: './table-history.component.html',
  styleUrls: ['./table-history.component.scss'],
})
export class TableHistoryComponent implements OnInit {
  @Input() history
  constructor(private languageService: LanguagesService) { }

  ngOnInit() {}

  InfoHistory(typeInfo: string, transaction,) {
    if (typeInfo == "title") {
      if (transaction.type == InfoCardHistoryByState.credit.type) {
        if (transaction.creditData.operation == InfoCardHistoryByState.refund.type) {
          return InfoCardHistoryByState[InfoCardHistoryByState.refund.type].title
        }
      }
      const title = (InfoCardHistoryByState[transaction.type].title as string).replace('@PoinstName', this.getTextIUCurrency.title)
      return title

    } else if (typeInfo == "subTitle") {
      if (transaction.type == InfoCardHistoryByState.credit.type) {
        if (transaction.creditData.operation == InfoCardHistoryByState.refund.type) {
          return InfoCardHistoryByState[InfoCardHistoryByState.refund.type].Subtitle
        }
        return InfoCardHistoryByState[InfoCardHistoryByState.credit.type].Subtitle
      }
      return InfoCardHistoryByState[transaction.type].Subtitle
    } else if (typeInfo == "detail") {
      if (transaction.type == InfoCardHistoryByState.credit.type) {
        if (transaction.creditData.operation == InfoCardHistoryByState.refund.type) {
          return transaction.refundData?.productName ? (InfoCardHistoryByState.refund.PrefixDetail + transaction.refundData?.productName) : transaction.description
        }
      }
      if (transaction.type == InfoCardHistoryByState.exchange.type) {
        return transaction.exchangeData?.productName ? (InfoCardHistoryByState.exchange.PrefixDetail + transaction.exchangeData?.productName) : transaction.description
      }
      return InfoCardHistoryByState[transaction.type].PrefixDetail
        ? (InfoCardHistoryByState[transaction.type].PrefixDetail + transaction.description)
        : transaction.description

    } else if (typeInfo == "detail2") {
      if (transaction.type == InfoCardHistoryByState.credit.type) {
        if (transaction.creditData.operation == InfoCardHistoryByState.refund.type) {

          return transaction.exchangeData?.productName ? (InfoCardHistoryByState.refund.PrefixDetail2 + transaction.exchangeData?.deliveryId) : ''
        }
      }
      if (transaction.type == InfoCardHistoryByState.exchange.type) {
        return transaction.exchangeData?.productName ? (InfoCardHistoryByState.exchange.PrefixDetail2 + transaction.exchangeData?.deliveryId) : ''
      }
      return ''

    }

  }

  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }
}
