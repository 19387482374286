import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsComponent } from './components/components.component';
import { ModulesComponent } from './modules/modules.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DesignSystemRoutingModule } from './design-system-routing.module';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './components/button-secondary/button-secondary.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { LabelComponent } from './components/label/label.component';
import { ItemComponent } from './components/item/item.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

@NgModule({
  declarations: [
    ComponentsComponent,
    ModulesComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    InputTextComponent,
    LabelComponent,
    ItemComponent,
    ProgressBarComponent
  ],
  exports: [
    ComponentsComponent,
    ModulesComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    InputTextComponent,
    LabelComponent,
    ItemComponent,
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DesignSystemRoutingModule,
    ReactiveFormsModule
  ]
})
export class DesignSystemModule { }
